import React from 'react';

import { BarsOutlined } from '@ant-design/icons';

import { Menu, Drawer, Layout, Button} from 'antd';
import { Link } from 'react-router-dom';

import { useState } from 'react';

import MyImage from '../../img/irlogo.gif';
import './NavBar.css';

import {
    useWindowWidth,
  } from '@react-hook/window-size'
  
import {MOBILE_SCREEN_TRIGGER_WIDTH} from '../../common/constants';

  const Navigation = () => {


    const BrandHeader = <div className="navbar-brand-text"><img className='navbar-logo' src={MyImage} alt='Ippon Ryu Ju Jitsu'/>Ippon Ryu Ju Jitsu</div>;

    const windowWidth = useWindowWidth()
    const isMobileMenu = windowWidth <= MOBILE_SCREEN_TRIGGER_WIDTH;
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const innerToggleMobileMenuOpen = () => {setMobileMenuOpen(!mobileMenuOpen);};

    const renderNavLinks = (extra, toggleMenuOpen) => {
        return [
            {
                key: extra+"home", 
                label: (<Link to="/" onClick={toggleMenuOpen}>Home</Link>)
            },
            {
                key: extra+"our_classes", 
                label: (<Link to="/classes" onClick={toggleMenuOpen}>Our Classes</Link>)
            },
            {
                key: extra+"locations", 
                label: (<Link to="/locations" onClick={toggleMenuOpen}>Locations</Link>)
            },
            {
                key: extra+"instructors", 
                label: (<Link to="/instructors" onClick={toggleMenuOpen}>Instructors</Link>)
            },
            {
                key: extra+"syllabus",
                label:'Syllabus',
                children: [
                    {
                        key:extra+"adult-syllabus",
                        label: (<Link to="/adults" onClick={toggleMenuOpen}>Adults</Link>)
                    }, 
                    {
                        key:extra+"family-syllabus",   
                        label: (<Link to="/juniors" onClick={toggleMenuOpen}>Juniors and Families</Link>)
                    }
                ]   
            }
        ]; 
    };


    const mobileMenu = <Button   onClick={innerToggleMobileMenuOpen} style={{ float:'right', lineHeight: '64px', marginTop: '12px' }} ><BarsOutlined/></Button>
    
    const fullMenu = <Menu key="fullmenu" mode="horizontal" 
        style={{ float:'right', lineHeight: '64px'}} 
        items = {renderNavLinks("fullmenu", innerToggleMobileMenuOpen)} >
    </Menu>;

    return <>
        <Drawer className='navigation-drawer' title={BrandHeader}
            placement='left'
            closable={false}
            onClose={innerToggleMobileMenuOpen}
            open={mobileMenuOpen}
            style={{
                fontSize:'xxx-large'
            }}>
            <Menu style={{lineHeight: '64px'}} mode="inline" items={renderNavLinks("drawer", innerToggleMobileMenuOpen)}>
                
            </Menu>
        </Drawer>

        <Layout.Header style={{background:'white'}}>
            {BrandHeader}
            {isMobileMenu? mobileMenu : fullMenu}            
        </Layout.Header>
    </>;

};

export default Navigation;
