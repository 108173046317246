import { makeAutoObservable } from 'mobx';
import {MOBILE_SCREEN_TRIGGER_WIDTH} from '../common/constants';
import { createContext } from 'react';

export default class SessionStore {

  constructor() {
    makeAutoObservable(this)
  }

  mobileMenuOpen = false;
  isMobileMenu = false;
  tab = 0;

  setIsMobileMenu = windowWidth => {
      this.isMobileMenu = windowWidth <= MOBILE_SCREEN_TRIGGER_WIDTH;
      //breakpoint in antd grid
  };

  toggleMobileMenuOpen = () => {
      if(!this.mobileMenuOpen) {
          if(this.isMobileMenu) {
              this.mobileMenuOpen = true;
          }
      } else {
          this.mobileMenuOpen = false;
      }
  };
}

export const StoreContext = createContext(SessionStore);
