import React from 'react';
import {Row, Col, Card} from 'antd';
import {Link} from 'react-router-dom';

import ForChildrenImage from './kids-training.jpg';
import ForAdultsImage from './mid-kick.png';
import ClassesImage from './action-shot.jpg';
import TrainingImage from './clubmembers.jpg';
import OnMat from './onmat.jpg'

const {Meta} = Card; 

const Home = () => {

    return (<div>
        <Row type='flex' justify='center' gutter={{xs: 0, md: 20}}>
            <Col>
                <Card title={<h1 className='ippon-ryu-font'>Welcome!</h1>}>
                    We're a Japanese Ju Jitsu Club running Thursday evenings between 6:30pm and 9pm out of Toynbee School in Chandlers Ford! All ages and abilities welcome, we're a club that runs for the
                    benefit of its Members with experienced Instructors and fun family-oriented atmosphere that underpins everything we do! <br/><br/>
                    If you'd like to jump straight to an idea of sessions and costs please go <a href="/adults">here</a> for adults and <a href="/juniors">here</a> for children and families
                </Card>
                <Card title={<h1 className='ippon-ryu-font'>News & Updates</h1>}>
                    <Card cover={<img src={OnMat} alt=''/>} type="inner" title={<h2 className='ippon-ryu-font'>September 2024! New Academic Year!</h2>}>
                    School's back and so are we! If you've got any interest at all in starting or coming back to Ju Jitsu please give us a call on one of the numbers below or mail us on <a href="mailto:ippon.ryu.jujitsu@gmail.com">ippon.ryu.jujitsu@gmail.com</a>
                    </Card>
                </Card>
            </Col>
        </Row>
        <br/>
        <Row type='flex' justify='center' gutter={{xs: 0, md: 20}}>
        <Col>
            <Link to="/juniors"><Card style={{width:'200px'}} cover={<img src={ForChildrenImage} alt=''/>}>
                <Meta title='Ju Jitsu for Children'></Meta>
            </Card></Link>
        </Col>
        <Col>
            <Link to="/adults"><Card style={{width:'200px'}} cover={<img src={ForAdultsImage} alt=''/>}>
                <Meta title='Ju Jitsu for Adults'></Meta>
            </Card></Link>
        </Col>
        <Col>
            <Link to="/classes"><Card style={{width:'200px'}} cover={<img src={ClassesImage} alt=''/>}>
                <Meta title='About our Classes'></Meta>
            </Card></Link>
        </Col>
        <Col>
            <Link to="/locations"><Card style={{width:'200px'}} cover={<img src={TrainingImage} alt=''/>}>
                <Meta title='Where we train'></Meta>
            </Card></Link>
        </Col>
        </Row>

</div>);
};

export default Home;