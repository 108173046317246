import './costs.css';

import React from 'react';

const AdultCosts = () => {
return <div>
    <h1 className='ippon-ryu-font'>Costs for Adults</h1>
    <hr/>
    Training and costs for Adults are shown below. To get a training discount where multiple students
    come from the same family, please speak to an Instructor. We cannot unfortunately discount Licence, Grading or
    equipment costs. Training discounts are available to full-time Students and the unemployed.

    <h2 className='ippon-ryu-font'>Start-up costs</h2>
    Once a student has trained for a few weeks and wants to continue training then we ask that you purchase a
    Judo suit (called a Gi) and a BJJAGB licence. You have to get the licence through us, and you can opt to get a
    suit through us or purchase this yourself - however we insist that this is plain white with no markings.

    <ul>
        <li>Licence is £20 paid annually. If you do not purchase a Licence then we're afraid you
            cannot carry on training.</li>
        <li>Suit is costs approximately £40 to £50 depending on the height and build of the Student. These only need replacing
            when they are worn out or when you reach Black Belt where you wear a different one with extra bling.</li>
    </ul>

    <h2 className='ippon-ryu-font'>Weekly training costs</h2>
    <ul>
        <li>If 2 or students come from the same family then speak to an Instructor to arrange a reduction on these fees</li>
        <li>The standard pay-on-the-day cost for a training session is £8.50</li>
        <li>Students &amp; Concessions pay-on-the-day cost is £5</li>
        <li>Pay Monthly for once-a-week training at any location is £30 paid at the beginning of the month</li>
    </ul>

    <h2 className='ippon-ryu-font'>Grading Costs</h2>
    <ul>
        <li>There is no charge for a tag grading</li>
        <li>There is a £15 Grading charge when taking a Belt Grading which includes the cost of the belt, applicable for all belts up to Black
            which is costed differently</li>
    </ul>
</div>
};

export default AdultCosts;