import './App.css';

import SessionStore, {StoreContext} from './stores/SessionStore';

import Navigation from './components/navigation/Navigation';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Layout} from 'antd';

// Pages

import SiteFooter from './components/footer';
import {Home, 
    OurClasses, 
    Locations, 
    Instructors, 
    AdultSyllabus, 
    FamilySyllabus,
    AdultCosts, 
    FamilyCosts,
} from './components/pages';

const { Content, Footer } = Layout;

const sessionStore = new SessionStore();

const App = () => (

<StoreContext.Provider value={sessionStore}>
    <Router>
        <Layout className='layout'>
            <Navigation/>
            <Content style={{ minHeight:'500px', padding: '0 50px 25px', background:'white' }}>
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/classes" element={<OurClasses />}></Route>
                <Route path="/locations" element={<Locations />}></Route>
                <Route path="/instructors" element={<Instructors />}></Route>
                <Route path="/adults" element={<AdultSyllabus />}></Route>
                <Route path="/juniors" element={<FamilySyllabus />}></Route>
                <Route path="/adult_costs" element={<AdultCosts />}></Route>
                <Route path="/junior_costs" element={<FamilyCosts />}></Route>
                </Routes>
            </Content>
            <Footer>
                <SiteFooter />
            </Footer>
        </Layout>
    </Router>
</StoreContext.Provider>
);
export default App;
