import React from 'react';
import {Row, Col, Card} from 'antd';

import './locations.css';
import ToynbeeImage from './toynbee-school-300x222.jpg';

const cardWidth = '640px';

const Locations =  () => {

    return <div>
        <h1 className='ippon-ryu-font'>Where we train</h1>
        <hr/>
        <Row>
            <Col>We currently train out of a Dojo located in Toynbee School's large dance studio. The Dojo is well equipped with adequate mats and toilets with showers and changing facilities</Col>
        </Row>
        <br/>
        <Row type='flex' justify='center' gutter={{xs: 0, md: 20}}>           
            <Col>
                <Card className='locations-card' style={{width: cardWidth}}
                      title={<div className=''>
                                <h2>Toynbee School</h2>
                                <h4>Bodycoats Rd, Chandlers Ford, SO53 2PL</h4>
                            </div>}
                      cover={<div className='locations-image-container'>
                                <img className='locations-image' src={ToynbeeImage} alt='Toynbee School'/>
                            </div>}>
                        <p>During term-time we train in the <strong>Large Dance Studio / New Sports Hall</strong> of Toynbee School on Thursday evenings.</p>
                        <p>Juniors and Family training runs from 6:20pm until 7:30pm</p>
                        <p>Seniors (14 years old and over) train from 7:30pm until 9pm</p>
                        <p>During the school Holidays we may temporarily relocate to the main Sports Hall and train on Wednesdays or we may have a break!</p>
                </Card>
            </Col>
        </Row>
    </div>
};


/*
description=
                    <img top className="location-image" src="static/img/knightwood-leisure-centre-300x222.jpg" alt="Card image cap" />

                <Card className="location-card">
                    <CardBody className="location-card-header-panel">
                        <CardTitle>
                        River Park Leisure Centre
                    </CardTitle>
                    <CardSubtitle>
                        Gordon Rd, Winchester SO23 7DD
                    </CardSubtitle>
                    </CardBody>
                    <CardImg top className="location-image" src="static/img/river-park.jpg" alt="Card image cap" />
                    <CardBody>
                        <p>On Sundays we train out of <strong>Studio 2</strong> in River Park Leisure Centre.</p>
                        <p>Combined Family and Seniors training runs from 6pm until 8pm all year round, except on Bank Holiday weekends</p>
                    </CardBody>
                </Card>
 */

export default Locations;